import React, { Fragment } from 'react';
import Modal from './Modal';

const ModalError = ({
  isOpen,
  message,
  subMessage,
  onClose,
}) => {
  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <Fragment>
            <span className='error-modal-message'>
              {message}
            </span>
            <span className='error-modal-sub-message'>
              {subMessage}
            </span>
            <button className='confirm-button h-40 mb-14' onClick={onClose}>
              확인
            </button>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default ModalError;