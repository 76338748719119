import React, { Fragment, useEffect } from 'react';
import * as GoogleAnalytics from '../../lib/google-analytics';
import Modal from '../Modal';

const CheckOut = ({
  isOpen,
  onClose,
  checkOut,
}) => {
  const onOk = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_confirm_ok',
    });
    checkOut();
  };

  const onCancel = () => {
    GoogleAnalytics.customEvent({
      category: 'button_click',
      action: 'button_click_checkout_confirm_cancel',
    });
    onClose();
  };

  useEffect(() => {
    if (isOpen) GoogleAnalytics.customEvent({
      category: 'modal_view',
      action: 'modal_view_checkout_confirm',
    });
  }, [isOpen]);

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        isClosable={false}
        onClose={onClose}
        Content={(
          <Fragment>
            <span className='check-out-title'>
              체크아웃을 하시겠습니까?
            </span>
            <span className='check-out-description'>
              체크아웃 시 해당 객실에 재입장 불가합니다.
            </span>
            <div className='check-out-notice-container'>
              <div className='check-out-notice-title-container'>
                <img className='notice-icon mt-1 mr-4' src='../../assets/images/exclamation-mark.png' alt='' />
                <span className='check-out-notice-title'>
                  추가 서비스 결제
                </span>
              </div>
              <span className='check-out-notice-description'>
                미니바 사용 및 호텔 서비스 추가 비용이 있을
                경우 프런트바에서 현장 결제해주세요.
              </span>
            </div>
            <div className='check-out-button-container'>
              <div className='cancel-button' onClick={onCancel}>
                <span className='cancel-button-text'>
                  아니요
                </span>
              </div>
              <div className='check-out-button' onClick={onOk}>
                <span className='check-out-button-text c-red'>
                  체크아웃 하기
                </span>
              </div>
            </div>
          </Fragment>
        )}
      />
    </Fragment>
  );
};

export default CheckOut;