import React, { Fragment, useEffect } from 'react';
import * as GoogleAnalytics from '../../lib/google-analytics';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const Page404 = ({
  history,
}) => {
  useEffect(() => {
    GoogleAnalytics.customEvent({
      category: 'page_view',
      action: 'page_view_404',
    });
  }, []);

  return (
    <Fragment>
      <div className='not-found-icon'>
        <FontAwesomeIcon icon={faQuestionCircle} />
      </div>
      <span className='not-found-code'>
        404
      </span>
      <span className='not-found-message'>
        페이지를 찾을 수 없습니다.
      </span>
    </Fragment>
  );
};

export default Page404;