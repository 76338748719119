import React, { Fragment } from 'react';

const Checkbox = ({
  isChecked,
  onCheck,
}) => {
  const setClassName = `agree-checkbox ${isChecked ? 'checked' : ''}`;
  const setImg = `../assets/images/${isChecked ? 'checked' : 'unchecked'}.png`;

  return (
    <Fragment>
      <div className={setClassName} onClick={onCheck}>
        <img className='checkbox' src={setImg} alt='' />
      </div>
    </Fragment>
  );
};

export default Checkbox;