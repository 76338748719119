import React, { Fragment } from 'react';
import Header from '../Header';
import Content from '../Content';
// import Footer from '../Footer';

const MainLayout = ({
  history,
  customStyle,
  ContentBody,
}) => {
  return (
    <Fragment>
      <Header history={history}/>
      <Content customStyle={customStyle} ContentBody={ContentBody}/>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default MainLayout;