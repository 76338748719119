import React, { Fragment } from 'react';

const CustomContent = ({
  ContentBody,
  customStyle,
}) => {
  return (
    <Fragment>
      <div className='content-container' style={customStyle}>
        {ContentBody}
      </div>
    </Fragment>
  );
};

export default CustomContent;