import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { routesList } from './index';
import ScrollToTop from '../components/ScrollToTop';
import PagePC from '../components/views/PagePC';

const Routes = () => {
  const isMobile = () => {
    const pc = 'win16|win32|win64|mac|macintel';
    if (navigator.platform) {
      if (pc.indexOf(navigator.platform.toLowerCase()) < 0) return true;
      else return false;
    }
    else return true;
  };

  return (
    <Router>
      <ScrollToTop />
      <Switch>
        {isMobile() ? routesList.map(({ path, component: Component }, index) => (
          <Route
            key={index}
            path={path}
            exact
            render={props => (
              <Component {...props} />
            )}
          />
        )) : (
          <Route
            render={props => (
              <PagePC {...props} />
            )}
          />
        )}
      </Switch>
    </Router>
  )
};

export default Routes;
