import axios from 'axios';
// import _ from 'lodash';

const baseURL = process.env.REACT_APP_H2O_BACKEND_BASEURL;

// config
const request = axios.create({
  baseURL,
});

const headers = (header) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': 'sBjXf2yC7d8kbFQlHZRRu5Lq6zIaMJovmKwm67O1',
      ...header && header,
    }
  };
};

export const logData = ({hotel, rsvnNo, progress, date, type, ref}) => {
  const data = {hotel, rsvnNo, progress, type, date, ref};
  request.post('/h2o-ss/log',
    data,
    headers({}),
  );
};