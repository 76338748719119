import React, { Fragment } from 'react';

const CustomHeader = ({
  history,
}) => {
  return (
    <Fragment>
      <div className='header-container h-0'>
        {/* <img className='header-logo' src='../assets/images/header-logo.png' alt='' />
        <span className='header-title'>체크인 서비스</span> */}
        {/* <img className='menu-icon' src='../assets/images/menu.png' alt='' /> */}
      </div>
    </Fragment>
  );
};

export default CustomHeader;